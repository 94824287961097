import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { api } from "../api";
import { MaintenanceWindowDto } from "../backend";
import { AppDispatch, AppThunk, AppThunkPromise } from "../store";

interface SystemState {
    maintenanceWindow: MaintenanceWindowDto
}

const initialState: SystemState = {
    maintenanceWindow: null
}

export const systemSlice = createSlice({
    name: "system",
    initialState,
    reducers: {
        ////
        // Server settings methods
        ////
        setMaintenanceWindow(state: SystemState, payload: PayloadAction<MaintenanceWindowDto>): void {
            state.maintenanceWindow = payload.payload
        },
    }
})

export const fetchMaintenanceWindow = (): AppThunkPromise<MaintenanceWindowDto> => async (dispatch: AppDispatch): Promise<MaintenanceWindowDto> => {
    const result = await api.getMaintenance()
    dispatch(systemSlice.actions.setMaintenanceWindow(result))
    return result
}

export const setMaintenanceWindow = (start: string, end: string): AppThunk => async (dispatch: AppDispatch) => {
    await api.setMaintenance({
        start: start.toString(),
        end: end.toString()
    })
    dispatch(fetchMaintenanceWindow())
}

export const clearMaintenanceWindow = (): AppThunk => async (dispatch: AppDispatch) => {
    await api.clearMaintenance()
    await dispatch(fetchMaintenanceWindow())
}
