import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { TimezoneDTO } from 'src/backend';
import { RootState } from 'src/store';



const timezonesAdapter = createEntityAdapter<TimezoneDTO>({
    selectId: (folder) => folder.id,
    sortComparer: (a, b) => String(a.description).localeCompare(b.description),
});


export const fetchTimezones = createAsyncThunk(
    'user/timezones',
    async () => {
        const data = await api.getTimezones()
        return data;
    }
)

export const timezoneSlice = createSlice({
    name: 'timezones',
    initialState: timezonesAdapter.getInitialState({
        loading: false,
        ids: [],
        entities: {},
        elements: [],
    }),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchTimezones.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTimezones.fulfilled, (state, action) => {
            timezonesAdapter.removeAll(state);
            timezonesAdapter.upsertMany(state, action.payload);
            state.loading = false;
        });
    }
});

export const { reducer } = timezoneSlice;

export const {
    selectIds: selectTimezoneIds,
    selectAll: selectAllTimezones,
    selectTotal: selectTotalTimezones,
} = timezonesAdapter.getSelectors((state: RootState) => state[timezoneSlice.name]);