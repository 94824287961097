import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { api } from 'src/api';
import { ShoeboxItemResponseDto } from 'src/backend';
import { lenderShoeBoxApi } from 'src/services/lenderShoeBoxApi';
import { AppThunkPromise, RootState } from 'src/store';
import { Loan } from 'src/types/loan';
import { processFileForUpload } from 'src/utils/process-file-for-upload';

import { licenseKeysSlice } from './license-keys';


interface ShoeBoxState {
    // key is the item id
    items: Record<string, ShoeboxItemResponseDto>;
    // key is the user id
    uploading: Record<string, boolean>;
}

const initialState: ShoeBoxState = {
    items: {},
    uploading: {},
};

export const shoeBoxSlice = createSlice({
    name: 'shoebox',
    initialState,
    reducers: {
        setItems(state: ShoeBoxState, action: { payload: { items: ShoeboxItemResponseDto[] } }): void {
            action.payload.items.forEach(item => {
                if (!isEqual(state.items[item.id], item)) {
                    state.items[item.id] = item;
                }
            });
            // if the item is not in the new list, remove it
            Object.keys(state.items).forEach(key => {
                if (!action.payload.items.find(item => item.id === key)) {
                    delete state.items[key];
                }
            });
        },
        removeItem: (state: ShoeBoxState, action: { payload: { itemId: string } }): void => {
            delete state.items[action.payload.itemId];
        },
        setUploading(state: ShoeBoxState, action: { payload: { userId: string, uploading: boolean } }): void {
            state.uploading[action.payload.userId] = action.payload.uploading;
        }
    }
});

const { actions } = shoeBoxSlice;

export const createLoanShoeBoxItem = ({ loanId, file, userId, onUploadProgress }: { loanId: string, file: File, userId: string, onUploadProgress?: (progress: number) => void }): AppThunkPromise<ShoeboxItemResponseDto> => async (dispatch: any, getState): Promise<ShoeboxItemResponseDto> => {
    const { [licenseKeysSlice.name]: { pdftronKey } } = getState();
    dispatch(actions.setUploading({ userId, uploading: true }));
    const finalFile = await processFileForUpload(file, pdftronKey);
    const document = await api.uploadDocument({
        file: finalFile,
        onUploadProgress
    });
    const item = await api.createShoeBoxItem({
        id: null,
        loanId,
        documentId: document.id,
        title: finalFile.name,
        userId,
        shoeboxType: 'LOAN',
        shoeboxOwnerId: null,
        uploadedById: userId
    });
    dispatch(actions.setUploading({ userId, uploading: false }));
    dispatch(getLoanShoeBoxItems(loanId));
    return item;
};

export const getLoanShoeBoxItems = (loanId: string): AppThunkPromise<ShoeboxItemResponseDto[]> => async (dispatch: any): Promise<ShoeboxItemResponseDto[]> => {
    const items = await api.getShoeBoxItemsByLoan({
        loan: loanId
    });
    dispatch(lenderShoeBoxApi.util.invalidateTags(['ShoeboxItemResponseDto']))
    if (Array.isArray(items)) {
        dispatch(actions.setItems({ items }));
        return items;
    } else {
        return []
    }
}

export const deleteShoeBoxItem = (id: string): AppThunkPromise<void> => async (dispatch: any): Promise<void> => {
    await api.deleteShoeBoxItem({
        id,
        documentId: null,
        loanId: null,
        title: null,
        userId: null,
        shoeboxType: 'LOAN',
        shoeboxOwnerId: null,
        uploadedById: null
    });
    // remove document from state
    dispatch(actions.removeItem({ itemId: id }));
    dispatch(lenderShoeBoxApi.util.invalidateTags(['ShoeboxItemResponseDto']))
}

export const isShoeboxUploadingSelector = (userId: string) => (state: RootState): boolean => {
    return state.shoebox.uploading[userId] || false;
}


export const selectLoanTotalShoeBoxItems = (loan: Loan, loggedInUserId: string) => createSelector([
    (state: RootState) => state[shoeBoxSlice.name].items,
], (items) => {
    if (!loan) {
        return 0;
    }
    const list = Object.values(items).flat();
    const onlyShowUserShoebox = loan.loanRoles.some(role => role.user.id === loggedInUserId && ['UNDERWRITER_LENDER', 'BORROWER', 'LEAD_BORROWER'].includes(role.role))
    if (onlyShowUserShoebox) {
        return list.filter(item => item.loanRole.loan === loan.id && item.loanRole.user.id === loggedInUserId).length;
    }
    return list.filter(item => item.loanRole.loan === loan.id).length;
})
